<template>
  <div class="activity">
    <Search :searchOptions="options" @search="getSearch">
      <div class="btns">
        <el-button class="btn" type="primary" @click="modalShow"
          >新增</el-button
        >
      </div>
    </Search>
    <div class="activity_content">
      <Table
        :tableOptions="tableOptions"
        :tableData="tableData"
        :page="page"
        :total="total"
        @update:page="updatePage"
      >
        <template v-slot:action="{ scope }">
          <div class="btns">
            <el-button type="text" @click="look('look', scope)">查看</el-button
            ><el-button type="text" @click="look('edit', scope)"
              >编辑</el-button
            >
            <!-- <el-button type="text" @click="dowCode(scope)"
              >生成二维码</el-button
            > -->
            <el-button
              type="text"
              :disabled="scope.activityStatus ? false : true"
              @click="dowCode(scope)"
              >生成二维码</el-button
            >
            <el-button type="text" style="color: red" @click="del(scope)"
              >删除</el-button
            >
          </div>
        </template>
      </Table>
    </div>
    <!-- 弹窗表单 -->
    <el-dialog
      title="活动坊"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :disabled="mode == 'look' ? true : false"
      >
        <el-form-item label="标题" prop="titleName">
          <el-input v-model="ruleForm.titleName" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="联系人" prop="contactName">
          <el-input v-model="ruleForm.contactName" placeholder="请输入联系人" />
        </el-form-item>
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input
            v-model="ruleForm.contactPhone"
            placeholder="请输入联系电话"
          />
        </el-form-item>
        <el-form-item label="最大报名数" prop="maxEnrollNumber">
          <el-input
            v-model.number="ruleForm.maxEnrollNumber"
            placeholder="请输入最大报名人数"
          />
        </el-form-item>
        <el-form-item label="活动时间" prop="activityTime">
          <el-date-picker
            @change="handleTimeActivity"
            v-model="ruleForm.activityTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="报名时间" prop="enrollTime">
          <el-date-picker
            v-model="ruleForm.enrollTime"
            type="datetimerange"
            @change="handleTime"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="活动图片" prop="activityPicture">
          <el-upload
            action="#"
            ref="upload"
            :limit="1"
            list-type="picture-card"
            :on-remove="handleRemove"
            :http-request="uploadSectionFile"
            :file-list="fileList"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="活动简介" prop="activityContent">
          <el-input
            v-model="ruleForm.activityContent"
            :rows="5"
            type="textarea"
            maxlength="300"
            show-word-limit
            placeholder="请输入活动简介"
          />
        </el-form-item>
        <el-form-item label="定位" prop="activityLocation">
          <div id="maps"></div>
        </el-form-item>
        <template v-if="isDisabled">
          <div class="titles">
            <span>参与活动人员：</span>
            <el-button
              link
              type="primary"
              :disabled="false"
              size="small"
              @click="download(ruleForm.id)"
              >下载列表</el-button
            >
          </div>
          <!--  -->
          <Table
            :action="false"
            :pagination="false"
            :tableOptions="detailTableOptions"
            :tableData="ruleForm.activityEnrollVOList"
          >
          </Table>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          :disabled="mode == 'look' ? true : false"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--  -->
    <el-dialog title="活动坊" :visible.sync="dialogVisibles" destroy-on-close>
      <div
        style="width: 100%; display: flex; justify-content: center"
        v-if="qrCodes"
      >
        <vue-qr
          ref="qrCode"
          :text="qrCodes"
          :color-dark="randomColor"
          :callback="qrCodeCallback"
          width="800"
          height="800"
        ></vue-qr>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibles = false">取 消</el-button>
        <el-button type="primary" @click="handleDow">下载二维码</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import Table from "../../components/Table.vue";
import VueQr from "vue-qr";
export default {
  components: {
    Search,
    Table,
    VueQr,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      qrCodes: null,
      randomColor: "black",
      qrCodeUrl: "", // 存放生成的二维码ur
      mode: "add",
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      options: [
        {
          type: "input",
          placeholder: "活动名称",
          prop: "name",
        },
      ],
      tableData: [],
      detailTableData: [],
      tableOptions: [
        {
          label: "活动名称",
          prop: "titleName",
        },
        {
          label: "活动开始时间",
          prop: "activityStartTime",
        },
        {
          label: "活动结束时间",
          prop: "activityEndTime",
        },
        {
          label: "活动报名开始时间",
          prop: "enrollStartTime",
        },
        {
          label: "活动报名截止时间",
          prop: "enrollEndTime",
        },
        {
          label: "报名人数",
          prop: "enrollNumber",
        },
      ],
      detailTableOptions: [
        { label: "姓名", prop: "nickName" },
        { label: "电话号码", prop: "phone" },
        {
          label: "签到状态",
          prop: "status",
        },
        { label: "签到时间", prop: "checkTime" },
      ],
      ruleForm: {
        titleName: "",
        contactPhone: "",
        contactName: "",
        maxEnrollNumber: "",
        enrollStartTime: "",
        enrollEndTime: "",
        activityStartTime: "",
        activityEndTime: "",
        activityPicture: [],
        activityContent: "",
        suggestStatus: "",
        activityLocation: "",
        activityTime: "", //临时变量
        enrollTime: "", //临时变量
      },
      rules: {
        titleName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            type: "string",
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        contactName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { min: 1, max: 20, message: "最多50个字", trigger: "blur" },
        ],
        maxEnrollNumber: [
          { required: true, message: "请输入最大报名人数", trigger: "blur" },
          { type: "number", message: "请输入数字" },
        ],
        enrollTime: [
          {
            required: true,
            message: "请选择报名开始时间与结束时间",
            trigger: "blur",
          },
        ],
        activityTime: [
          {
            required: true,
            message: "请选择活动开始时间与结束时间",
            trigger: "blur",
          },
        ],
        activityPicture: [
          { required: true, message: "请上传活动图片", trigger: "blur" },
        ],
        activityContent: [
          { required: true, message: "请输入活动简介", trigger: "blur" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        suggestStatus: [
          { required: true, message: "请选择是否推荐", trigger: "blur" },
        ],
        activityLocation: [
          { required: true, message: "请标记活动地址", trigger: "blur" },
        ],
      },
      fileList: [],
      markerLayer: null,
      mapInstance: null,
      isDisabled: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    // 下载二维码
    handleDow() {
      var base64 = this.qrCodeUrl.toString(); // imgSrc 就是base64哈
      var byteCharacters = atob(
        base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
      );
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {
        type: undefined,
      });
      var aLink = document.createElement("a");
      aLink.download = "签到二维码.jpg"; //这里写保存时的图片名称
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    },
    getColor() {
      var str = "#";
      var arr = [
        "1",
        "2",
        "3",
        "4",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
      ];
      for (var i = 0; i < 6; i++) {
        var num = parseInt(Math.random() * 16);
        str += arr[num];
      }
      return str;
    },
    qrCodeCallback(url) {
      this.qrCodeUrl = url;
    },
    // 下载表格
    async download(id) {
      const res = await this.$dow(
        `/xng/backed/activity/export/selectOneById?activityId=${id}`
      );
      console.log(res);
      let url = window.URL.createObjectURL(res);
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "参与活动人员列表");
      document.body.appendChild(link);
      link.click();
    },
    async look(type, row) {
      this.mode = type;
      const { flag, data } = await this.$get(
        "/xng/backed/activity/selectOneById",
        { id: row.id }
      );
      if (flag) {
        this.ruleForm = Object.assign(
          {},
          {
            ...data,
            activityPicture: data.activityPicture ? [data.activityPicture] : [],
            activityTime: [
              new Date(data.activityStartTime),
              new Date(data.activityEndTime),
            ],
            enrollTime: [
              new Date(data.enrollStartTime),
              new Date(data.enrollEndTime),
            ],
            activityEnrollVOList: data.activityEnrollVOList?.map((item) => {
              return {
                ...item,
                status: item.checkStatus ? "已签到" : "未签到",
              };
            }),
          }
        );
        this.dialogVisible = true;
        this.$nextTick(() => {
          if (data.activityPicture) {
            this.fileList = [
              {
                url: data.activityPicture,
              },
            ];
          }
          if (type == "look") {
            this.isDisabled = true;
          } else {
            this.isDisabled = false;
          }
          this.initMap();
        });
      }
    },
    del(row) {
      this.$confirm("此操作将删除该信息, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { flag, message } = await this.$del(
            `/xng/backed/activity/delActivityBase?id=${row.id}`
          );
          if (flag) {
            this.loadData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message.error(message);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getSearch(val) {
      this.page = {
        pageNum: 1,
        pageSize: 10,
        ...val,
      };
      this.loadData();
    },
    // 加载数据
    async loadData() {
      const { flag, data } = await this.$get(
        "/xng/backed/activity/selectPageListByName",
        this.page
      );
      if (flag) {
        this.total = data.total;
        this.tableData = data.rows.map((item) => {
          return {
            ...item,
            url: item.activityPicture,
          };
        });
      }
    },
    modalShow() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initMap();
      });
    },
    updatePage(val) {
      this.page.pageNum = val;
      this.loadData();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          this.ruleForm = {};
          this.mode = "add";
          this.fileList = [];
        })
        .catch((_) => {});
    },
    // 图片上传
    async uploadSectionFile(params) {
      const formData = new FormData();
      console.log(formData);

      formData.append("files", params.file);
      const res = await this.$post("/clue/upLoad", formData);
      if (res.flag) {
        this.ruleForm.activityPicture.push(res.data[0]);
        this.fileList.push({ url: res.data[0] });
      }
    },
    handleRemove(file, fileList) {
      this.ruleForm.activityPicture.map((item, index) => {
        if (item == file.url) {
          this.ruleForm.activityPicture.splice(index, 1);
          this.fileList.splice(index, 1);
        }
      });
    },
    initMap() {
      let arr = [];
      if (this.ruleForm.id) {
        arr = this.ruleForm.activityLocation.split(",");
      }
      // console.log(arr);
      //定义地图中心点坐标
      let center = new window.TMap.LatLng(
        arr.length ? arr[0] : 30.639047,
        arr.length ? arr[1] : 104.096055
      );
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      let maps = new window.TMap.Map(document.getElementById("maps"), {
        center: center, //设置地图中心点坐标
        zoom: 16, //设置地图缩放级别
      });
      if (this.ruleForm.id) {
        console.log(arr);
        this.markerLayer = new TMap.MultiMarker({
          id: "marker-layer", //图层id
          map: maps,
          styles: {
            marker: new TMap.MarkerStyle({
              width: 25,
              height: 35,
              anchor: { x: 16, y: 32 },
              src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png",
            }),
          },
          geometries: [
            {
              id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
              position: new window.TMap.LatLng(arr[0], arr[1]), //点标记坐标位置
            },
          ],
        });
      }
      if (!this.isDisabled) {
        maps.on("click", (evt) => {
          if (this.markerLayer?.id) {
            this.markerLayer.setMap(null);
          }
          this.markerLayer = new TMap.MultiMarker({
            id: "marker-layer", //图层id
            map: maps,
            styles: {
              marker: new TMap.MarkerStyle({
                width: 25,
                height: 35,
                anchor: { x: 16, y: 32 },
                src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png",
              }),
            },
            geometries: [
              {
                id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                position: new window.TMap.LatLng(
                  evt.latLng.lat,
                  evt.latLng.lng
                ), //点标记坐标位置
              },
            ],
          });
          this.ruleForm = {
            ...this.ruleForm,
            activityLocation: `${evt.latLng.lat},${evt.latLng.lng}`,
          };
        });
        this.mapInstance = maps;
      }
    },
    // 时间格式化
    formatTime(time) {
      let date = new Date(time);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {
            ...this.ruleForm,
            enrollStartTime: this.formatTime(this.ruleForm.enrollTime[0]),
            enrollEndTime: this.formatTime(this.ruleForm.enrollTime[1]),
            activityStartTime: this.formatTime(this.ruleForm.activityTime[0]),
            activityEndTime: this.formatTime(this.ruleForm.activityTime[1]),
            activityPicture: this.ruleForm.activityPicture.length
              ? this.ruleForm.activityPicture[0]
              : "",
          };
          delete params.enrollTime;
          delete params.activityTime;
          if (this.ruleForm.id) {
            const { flag } = await this.$post(
              "/xng/backed/activity/updateActivityBase",
              params
            );
            if (flag) {
              this.loadData();
              this.dialogVisible = false;
              this.$message({
                message: "编辑成功",
                type: "success",
              });
            } else {
              this.$message.error("编辑失败");
            }
          } else {
            const { flag } = await this.$post(
              "/xng/backed/activity/addActivity",
              params
            );
            if (flag) {
              this.loadData();
              this.dialogVisible = false;
              this.$message({
                message: "新增成功",
                type: "success",
              });
            } else {
              this.$message.error("新增失败");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 判断报名时间比活动时间早
    handleTime(val) {
      if (this.ruleForm?.activityTime?.length) {
        if (
          this.ruleForm?.enrollTime[0] > this.ruleForm?.activityTime[0] ||
          this.ruleForm?.enrollTime[0] > this.ruleForm?.activityTime[1]
        ) {
          this.$message.error("报名开始时间不能晚于活动开始时间");
          this.ruleForm.enrollTime = [];
        }
        if (
          this.ruleForm?.enrollTime[1] > this.ruleForm?.activityTime[0] ||
          this.ruleForm?.enrollTime[1] > this.ruleForm?.activityTime[1]
        ) {
          this.$message.error("报名结束时间不能晚于活动结束时间");
          this.ruleForm.enrollTime = [];
        }
      }
    },
    // 活动时间比报名时间晚且不相等
    handleTimeActivity(val) {
      if (this.ruleForm.enrollTime?.length) {
        if (
          this.ruleForm?.enrollTime[0] > this.ruleForm?.activityTime[0] ||
          this.ruleForm?.enrollTime[0] > this.ruleForm?.activityTime[1]
        ) {
          this.$message.error("报名开始时间不能晚于活动开始时间");
          this.ruleForm.activityTime = [];
        }
        if (
          this.ruleForm?.enrollTime[1] > this.ruleForm?.activityTime[0] ||
          this.ruleForm?.enrollTime[1] > this.ruleForm?.activityTime[1]
        ) {
          this.$message.error("报名结束时间不能晚于活动结束时间");
          this.ruleForm.activityTime = [];
        }
      }
    },
    dowCode(row) {
      this.$confirm("是否确认生成二维码?", "生成二维码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dialogVisibles = true;
          this.$nextTick(() => {
            this.qrCodes = row.activityCode;
          });

          this.$message({
            type: "success",
            message: "生成成功",
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.activity {
  width: 100%;

  .btns {
    padding: 0 10px;
  }

  .activity_content {
    width: 100%;
  }
}
</style>
